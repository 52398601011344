import 'bulma/css/bulma.css';
import 'font-awesome/css/font-awesome.css';


document.addEventListener('DOMContentLoaded', () => {

  const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');

  function switchTheme(e) {
      if (e.target.checked) {
          document.body.classList.add('dark-mode');
          localStorage.setItem('theme', 'dark'); // Set the theme in localStorage
      } else {
          document.body.classList.remove('dark-mode');
          localStorage.setItem('theme', 'light'); // Set the theme in localStorage
      }
  }

  toggleSwitch.addEventListener('change', switchTheme, false);

  function loadTheme() {
      const currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : null;
      if (currentTheme) {
          document.body.classList.toggle('dark-mode', currentTheme === 'dark');
          toggleSwitch.checked = (currentTheme === 'dark');
      }
  }

  loadTheme(); // Apply the theme on load


  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Add a click event on each of them
  $navbarBurgers.forEach( el => {
    el.addEventListener('click', () => {

      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');

    });
  });

});